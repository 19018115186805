/**
 * Created by Matthieu on 04/02/2021.
 */
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RectAreaLightHelper }  from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
var EventEmitter = require('events').EventEmitter;

import Stats from 'stats.js'

export default class ModelViewer extends EventEmitter
{

    constructor(_basePath = "/", _modelName = "file.fbx") {

        super();

        this.modelName = _modelName;
        this.basePath = _basePath;

        //this.displayStats();
        this.initSceneCameraRenderer();
        this.initEnv();
        //this.initSSAO();
        this.initProjectionShadow();
        this.initLights(false);
        this.initControls();
        this.loadModelInfos();

        this.render();
        window.addEventListener( 'resize', this.onWindowResize.bind(this), false );
    }

    displayStats()
    {
        this.stats = new Stats();
        this.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild( this.stats.dom );
    }

    initSceneCameraRenderer()
    {
        let me = this;
        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1500 );

        this.camera.position.x = 100;
        this.camera.position.y = 150;
        this.camera.position.z = 250;

        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha:true});
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        this.renderer.physicallyCorrectLights = true;
        //this.renderer.toneMapping = THREE.ReinhardToneMapping;
        //this.renderer.toneMappingExposure = 3;

        this.renderer.outputEncoding = THREE.sRGBEncoding;

        //this.renderer.gammaOutput = true;
        //this.renderer.gammaFactor = 1;

        document.getElementById("ModelViewer").appendChild( this.renderer.domElement );
        this.renderer.domElement.classList.add("gradiant");
    }


    initEnv()
    {
        let me = this;

        const pmremGenerator = new THREE.PMREMGenerator( me.renderer );
        pmremGenerator.compileEquirectangularShader();

        new RGBELoader()
            .setDataType( THREE.UnsignedByteType )
            .setPath( me.basePath+"/src/threejs/envmap/equirectangular/" )
            .load( 'christmas_photo_studio_04_2k.hdr', function ( texture ) {

                const envMap = pmremGenerator.fromEquirectangular(texture).texture;
                //envMap.encoding = THREE.sRGBEncoding;
                //me.scene.background = envMap;
                me.scene.environment = envMap;

                texture.dispose();
                pmremGenerator.dispose();

            });

    }

    initSSAO()
    {
        this.composer = new EffectComposer( this.renderer );

        const ssaoPass = new SSAOPass( this.scene, this.camera, window.innerWidth, window.innerHeight );
        ssaoPass.kernelRadius = 16;
        this.composer.addPass( ssaoPass );

        // Init gui
        const gui = new GUI();

        gui.add( ssaoPass, 'output', {
            'Default': SSAOPass.OUTPUT.Default,
            'SSAO Only': SSAOPass.OUTPUT.SSAO,
            'SSAO Only + Blur': SSAOPass.OUTPUT.Blur,
            'Beauty': SSAOPass.OUTPUT.Beauty,
            'Depth': SSAOPass.OUTPUT.Depth,
            'Normal': SSAOPass.OUTPUT.Normal
        } ).onChange( function ( value ) {

            ssaoPass.output = parseInt( value );

        } );
        gui.add( ssaoPass, 'kernelRadius' ).min( 0 ).max( 32 );
        gui.add( ssaoPass, 'minDistance' ).min( 0.001 ).max( 0.02 );
        gui.add( ssaoPass, 'maxDistance' ).min( 0.01 ).max( 0.3 );
    }

    initProjectionShadow()
    {
        const PLANE_WIDTH = 400;
        const PLANE_HEIGHT = 400;
        const CAMERA_HEIGHT = 300;

        this.state = {
            shadow: {
                blur: 3.5,
                darkness: 0.7,
                opacity: 1,
            },
            plane: {
                color: '#ffffff',
                opacity: 1,
            },
            showWireframe: false,
        };

        let shadowGroup = new THREE.Group();
        this.scene.add( shadowGroup );

        // the render target that will show the shadows in the plane texture
        this.renderTarget = new THREE.WebGLRenderTarget( 512, 512 );
        this.renderTarget.texture.generateMipmaps = false;

        // the render target that we will use to blur the first render target
        this.renderTargetBlur = new THREE.WebGLRenderTarget( 512, 512 );
        this.renderTargetBlur.texture.generateMipmaps = false;

        // make a plane and make it face up
        const planeGeometry = new THREE.PlaneGeometry( PLANE_WIDTH, PLANE_HEIGHT ).rotateX( Math.PI / 2 );
        const planeMaterial = new THREE.MeshBasicMaterial( {
            map: this.renderTarget.texture,
            opacity: this.state.shadow.opacity,
            transparent: true,
            depthWrite: false,
        } );
        let plane = new THREE.Mesh( planeGeometry, planeMaterial );
        // make sure it's rendered after the fillPlane
        plane.renderOrder = 1;
        shadowGroup.add( plane );

        // the y from the texture is flipped!
        plane.scale.y = - 1;

        // the plane onto which to blur the texture
        this.blurPlane = new THREE.Mesh( planeGeometry );
        this.blurPlane.visible = false;
        shadowGroup.add( this.blurPlane );

/*
        // the plane with the color of the ground
        const fillPlaneMaterial = new THREE.MeshBasicMaterial( {
            color: this.state.plane.color,
            opacity: this.state.plane.opacity,
            transparent: true,
            depthWrite: false,
        } );
        let fillPlane = new THREE.Mesh( planeGeometry, fillPlaneMaterial );
        fillPlane.rotateX( Math.PI );
        shadowGroup.add( fillPlane );
*/

        // the camera to render the depth material from
        this.shadowCamera = new THREE.OrthographicCamera( - PLANE_WIDTH / 2, PLANE_WIDTH / 2, PLANE_HEIGHT / 2, - PLANE_HEIGHT / 2, 0, CAMERA_HEIGHT );
        this.shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
        shadowGroup.add( this.shadowCamera );

        let cameraHelper = new THREE.CameraHelper( this.shadowCamera );

        // like MeshDepthMaterial, but goes from black to transparent
        this.depthMaterial = new THREE.MeshDepthMaterial();
        this.depthMaterial.userData.darkness = { value: this.state.shadow.darkness };
        let me = this;
        this.depthMaterial.onBeforeCompile = function ( shader ) {

            shader.uniforms.darkness = me.depthMaterial.userData.darkness;
            shader.fragmentShader = /* glsl */`
						uniform float darkness;
						${shader.fragmentShader.replace(
                'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
                'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
            )}`;

        };

        this.depthMaterial.depthTest = false;
        this.depthMaterial.depthWrite = false;

        this.horizontalBlurMaterial = new THREE.ShaderMaterial( HorizontalBlurShader );
        this.horizontalBlurMaterial.depthTest = false;

        this.verticalBlurMaterial = new THREE.ShaderMaterial( VerticalBlurShader );
        this.verticalBlurMaterial.depthTest = false;
    }


    initLights(debug)
    {
        const lightTop = new THREE.RectAreaLight( 0xffffff, 8,  100, 100 );
        //const rectLightTop = new THREE.DirectionalLight( 0xffffff, 1.2 );
        lightTop.position.set( 0, 250, 100 );
        lightTop.lookAt( 0, 0, 0 );
        this.scene.add( lightTop );
        if(debug)
            lightTop.add( new RectAreaLightHelper( lightTop ) );


        const lightFrontRight = new THREE.RectAreaLight( 0xF5D576, 3,  100, 100 );
        lightFrontRight.position.set( 150, 50, 300 );
        lightFrontRight.lookAt( 0, 0, 0 );
        this.scene.add( lightFrontRight );
        if(debug)
            lightFrontRight.add( new RectAreaLightHelper( lightFrontRight ) );

        const lightLeft = new THREE.RectAreaLight( 0xFFFFFF, 5,  100, 100 );
        lightLeft.position.set( -300, 50, 100 );
        lightLeft.lookAt( 0, 0, 0 );
        this.scene.add( lightLeft );
        if(debug)
            lightLeft.add( new RectAreaLightHelper( lightLeft ) );

        const lightBackRight = new THREE.RectAreaLight( 0xB3D5FF, 2.7,  300, 300 );
        lightBackRight.position.set( 250, 50, -250 );
        lightBackRight.lookAt( 0, 0, 0 );
        this.scene.add( lightBackRight );
        if(debug)
            lightBackRight.add( new RectAreaLightHelper( lightBackRight ) );

        const lightBackLeft = new THREE.RectAreaLight( 0xFAB6D5, 0.8,  200, 200 );
        lightBackLeft.position.set( -200, 50, -200 );
        lightBackLeft.lookAt( 0, 50, 0 );
        this.scene.add( lightBackLeft );
        if(debug)
            lightBackLeft.add( new RectAreaLightHelper( lightBackLeft ) );

        var shodowLight = new THREE.DirectionalLight( 0xffffff );
        shodowLight.position.set( 150, 150, 100 );
        shodowLight.lookAt(0, 0, 0);
        shodowLight.intensity = 1;
        shodowLight.shadow.mapSize.width = 2048;
        shodowLight.shadow.mapSize.height = 2048;
        shodowLight.shadow.radius = 1;
        shodowLight.shadow.bias = -0.004;
        shodowLight.castShadow = true;
        shodowLight.shadow.camera.near = 0.5;
        shodowLight.shadow.camera.far = 400;
        let d = 100;
        shodowLight.shadow.camera.left = d;
        shodowLight.shadow.camera.right = -d;
        shodowLight.shadow.camera.top = d;
        shodowLight.shadow.camera.bottom = -d;
        this.scene.add( shodowLight );

        if(debug)
            shodowLight.add( new THREE.CameraHelper(shodowLight.shadow.camera) );


        // Init gui
        if(debug)
        {
            const gui = new GUI();

            gui.add( lightTop, 'intensity' ).min( 0 ).max( 20 );
            gui.add( lightFrontRight, 'intensity' ).min( 0 ).max( 20 );
            gui.add( lightLeft, 'intensity' ).min( 0 ).max( 20 );
            gui.add( lightBackRight, 'intensity' ).min( 0 ).max( 20 );
            gui.add( lightBackLeft, 'intensity' ).min( 0 ).max( 20 );
        }
    }



    initControls()
    {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.08;
        this.controls.maxPolarAngle = Math.PI/2 + Math.PI/20;
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.PAN
        }
        this.controls.target.set( 0, 60, 0 );
    }


    loadModelInfos()
    {
        let me = this;
        let model = me.modelName;
        me.modelFolder = me.basePath+"/3dfiles/"+model+'/';

        $.getJSON(me.modelFolder+model+".json", function(json) {
            me.data = json;
            me.emit('data-loaded');
            me.loadFbx(function(){
                me.createMaterials();
                me.createNavigation();
            });

        });
    }

    createMaterials()
    {
        let me = this;
        me.material = {};

        for (const [material_name, material_list] of Object.entries(me.data.material)) {

            me.material[material_name] = [];



            for (const [key, map_list] of Object.entries(material_list)) {

                //0 - diffuse, 1 - metallic, 2 - normal, 3 - roughness, 4 - opacity
                let textures_loader = [];
                map_list.forEach(function(map)
                {
                    let loader = new THREE.TextureLoader().load( me.basePath+map);
                    loader.encoding = THREE.sRGBEncoding;
                    textures_loader.push(loader);
                });

                let finition = key.split("_")[1];

                //glass - mirror

                switch(finition)
                {
                    case "glass":


                        me.material[material_name].push(new THREE.MeshPhysicalMaterial({
                            name:key,
                            metalness: .9,
                            roughness: .05,
                            envMapIntensity: 0.9,
                            clearcoat: 1,
                            transparent: true,
                            opacity: .3,
                            reflectivity: 0.2,
                            refractionRatio: 0.985,
                            ior: 0.9
                        }));

                        break;
                    case "mirror":

                        me.material[material_name].push(new THREE.MeshPhysicalMaterial({
                            name:key,
                            metalness: 1,
                            roughness: 0,
                            envMapIntensity: 0.9,
                            clearcoat: 1,
                            transparent: false,
// transmission: .95,
                            opacity: 1,
                            reflectivity: 1,
                            refractionRatio: 0.985,
                            ior: 0.9
                        }));

                        break;
                    default:
                        if(textures_loader[4])
                        {
                            me.material[material_name].push(new THREE.MeshStandardMaterial( {
                                name:key,
                                color: 0xffffff,
                                alphaMap:textures_loader[4],
                                transparent: true,
                                side: THREE.DoubleSide,
                                /*opacity:0.5,
                                refractionRatio: 0.5,
                                ior: 0.5,*/
                                map: textures_loader[0],
                                metalnessMap: textures_loader[1],
                                metalness:1,
                                normalMap:textures_loader[2],
                                normalScale:new THREE.Vector2(1, 1),
                                roughnessMap:textures_loader[3],
                                roughness:1,
                                envMapIntensity:1
                                //side:THREE.DoubleSide
                            }));
                        } else {

                            console.log(me.modelName);

                            let normalScale = new THREE.Vector2(1, 1);
                            if(me.modelName.includes("ming") && finition === "black") normalScale = new THREE.Vector2(0, 0);

                            me.material[material_name].push(new THREE.MeshStandardMaterial( {
                                name:key,
                                color: 0xffffff,
                                /*transparent: true,
                                opacity:0.5,
                                refractionRatio: 0.5,
                                ior: 0.5,*/
                                map: textures_loader[0],
                                metalnessMap: textures_loader[1],
                                metalness:1,
                                normalMap:textures_loader[2],
                                normalScale:normalScale,
                                roughnessMap:textures_loader[3],
                                roughness:1,
                                envMapIntensity:1
                                //side:THREE.DoubleSide
                            }));
                        }
                }
            }
        }

        for (const [material_name, material_list] of Object.entries(me.material)) {
            me.switchTexture(material_name, 0);
        }

    }

    createNavigation()
    {
        let me = this;
        let $nav = $("<ul></ul>");

        for (const [material_name, material_list] of Object.entries(me.material)) {

            $('<input>').attr({
                type: 'hidden',
                name: material_name,
                value: 0
            }).appendTo('form');

            if(material_list.length <= 1) continue;

            let list = "<li><div class='cat-name'>"+material_name+"</div>";
            list = "<li>";
            let sublist = "<ul>";

            let index = 0;
            material_list.forEach(function(material){
                let name = material.name.split("_")[1];
                let materialThumb = window.LayoutVars.fmkHttpRoot+"src/img/"+name+".jpg";

                sublist += "" +
                    "<li class='switch_material' " +
                    "material-index="+index+" " +
                    "material-type="+material_name+" " +
                    "style='background-image:url("+materialThumb+")'" +
                    "></li>";
                index++;
            });

            sublist += "</ul>";

            list += sublist;
            list += "</li>";

            $nav.append(list);
        }

        $("#nav-texture").append($nav);


        $(".switch_material").click(function(e){
            let type = $(this).attr("material-type");
            let index = $(this).attr("material-index");

            me.switchTexture(type, index);
        });
    }


    switchTexture(type, id)
    {
        let me = this;
        me.model.traverse(function (child) {

            if (child.isMesh) {
                if(child.material.name.includes(type))
                {
                    $('input[name='+type+']').val(id);
                    child.material = me.material[type][id];
                    child.material.needsUpdate = true;

                }
            }
        });
    }

    loadFbx(cbComplete)
    {
        let me = this;

        const fbxLoader = new FBXLoader();
        fbxLoader.load(me.basePath+me.data.fbx,
            (object) => {
                me.model = object;
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });

                if($(".loader").length)
                {
                    $(".loader").addClass("complete");
                }


                me.scene.add(me.model);
                if(cbComplete) cbComplete();
            },
            (xhr) => {
                //console.log(xhr.total);
                console.log((xhr.loaded / xhr.total * 100) + ' % loaded')
            },
            (error) => {
                console.log(error);
            }
        )
    }



    blurShadow( amount ) {

        this.blurPlane.visible = true;

        // blur horizontally and draw in the renderTargetBlur
        this.blurPlane.material = this.horizontalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTarget.texture;
        this.horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTargetBlur );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        // blur vertically and draw in the main renderTarget
        this.blurPlane.material = this.verticalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTargetBlur.texture;
        this.verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.blurPlane, this.shadowCamera );

        this.blurPlane.visible = false;

    }


    renderGroundShadow()
    {
        const initialBackground = this.scene.background;
        this.scene.background = null;

        // force the depthMaterial to everything
        //cameraHelper.visible = false;
        this.scene.overrideMaterial = this.depthMaterial;

        // render to the render target to get the depths
        this.renderer.setRenderTarget( this.renderTarget );
        this.renderer.render( this.scene, this.shadowCamera );

        // and reset the override material
        this.scene.overrideMaterial = null;
        //ThreeViewer.cameraHelper.visible = true;

        this.blurShadow( this.state.shadow.blur );

        // a second pass to reduce the artifacts
        // (0.4 is the minimum blur amout so that the artifacts are gone)
        this.blurShadow( this.state.shadow.blur * 0.4 );

        // reset and render the normal scene
        this.renderer.setRenderTarget( null );
        this.scene.background = initialBackground;
    }

    render()
    {
        if(this.stats) this.stats.begin();
        this.controls.update();

        this.renderGroundShadow();
        //if(this.model) this.model.rotateY(0.001);

        if(this.composer) this.composer.render();
        else this.renderer.render( this.scene, this.camera );

        if(this.stats) this.stats.end();

        requestAnimationFrame( this.render.bind(this) );
    }


    onWindowResize()
    {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( window.innerWidth, window.innerHeight );
    }
}