import './index.less'

import $ from 'jquery'
window.$ = $;

import ModelViewer from './threejs/ModelViewer'

let viewer = new ModelViewer(LayoutVars.fmkHttpRoot, LayoutVars.model);
viewer.on("data-loaded", function(){

    $('body').on('click', '#download-zip', function() {

        let data = {files:[]};
        data.modelName = viewer.modelName;
        data.modelFolder = viewer.modelFolder;
        data.files.push(viewer.data.fbx);

        $('form input').each(function( index ) {
            let matName = $(this).attr("name");
            let id = $(this).val();
            let materialObject = viewer.data.material[matName];
            materialObject[Object.keys(materialObject)[id]].forEach(function(item){
                data.files.push(item);
            })
        });


        $.ajax({
            url : 'zipper.php',
            type : 'POST',
            data: {data:JSON.stringify(data)},
            success : function (result) {
                window.location="download.php?data="+JSON.stringify(data);
            },
            error : function () {
                console.log ('error');
            }
        });
    });


});
